/* ---------------------------------
MarkdownContainer
--------------------------------- */

import { Theme } from "@chakra-ui/react";
import styled from "@emotion/styled";

/**
 * This component collects base common styles
 * for markdown-generated HTML
 */
const MarkdownContainer = styled.main`
  // generic
  > *:not(:last-child) {
    margin-bottom: 32px;
  }

  > *:last-child {
    margin-bottom: 64px;
  }

  // headings
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: ${({ theme }) => (theme as Theme)?.fontWeights.bold ?? "bold"};
    font-family: ${({ theme }) =>
      (theme as Theme)?.fonts?.heading ??
      (theme as Theme)?.fonts?.body ??
      "sans-serif"};
    margin: 0.75rem 0 0 0;
  }

  h1 {
    font-size: ${({ theme }) =>
      (theme as Theme)?.fontSizes?.["4xl"] ?? "2.25rem"};
  }
  h2 {
    font-size: ${({ theme }) =>
      (theme as Theme)?.fontSizes?.["3xl"] ?? "1.875rem"};
  }
  h3 {
    font-size: ${({ theme }) =>
      (theme as Theme)?.fontSizes?.["2xl"] ?? "1.5rem"};
  }
  h4 {
    font-size: ${({ theme }) => (theme as Theme)?.fontSizes?.xl ?? "1.25rem"};
  }
  h5 {
    font-size: ${({ theme }) => (theme as Theme)?.fontSizes?.lg ?? "1.125rem"};
  }
  h6 {
    font-size: ${({ theme }) => (theme as Theme)?.fontSizes?.md ?? "1rem"};
  }

  // blockquotes
  blockquote {
    margin-left: ${({ theme }) => (theme as Theme)?.space?.["4"] ?? "1rem"};
  }

  // paragraphs
  p {
    text-align: justify;
  }

  // other
  .gatsby-resp-image-wrapper {
    //height: 300px; it's enabled in gatsby-config

    & > .gatsby-resp-image-image,
    & > img {
      object-fit: cover;
    }

    & > .gatsby-resp-image-background-image {
    }
  }

  // media queries
  @media (min-width: 768px) {
    p {
      font-size: 18px;
    }
  }
`;

export default MarkdownContainer;
