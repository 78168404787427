/* ---------------------------------
NewsTemplate
--------------------------------- */

import React from "react";
import Layout from "../components/Layout";
import { Link as GatsbyLink, PageProps } from "gatsby";
import { Flex, Heading, Link } from "@chakra-ui/react";
import Section from "../components/Section";
import MarkdownContainer from "../components/MarkdownContainer";
import { makeSlug } from "../utils";
import { ArrowLeft, ArrowRight } from "../components/Icons";

const NewsDetail: React.FC<PageProps> = ({ pageContext }) => {
  const {
    node: { childMarkdownRemark: md },
    prev,
    next,
  } = pageContext;

  const prev_frontmatter = prev?.childMarkdownRemark?.frontmatter;
  const next_frontmatter = next?.childMarkdownRemark?.frontmatter;

  const prevPost =
    prev_frontmatter &&
    makeSlug([prev_frontmatter.timestamp, prev_frontmatter.title], "news");

  const nextPost =
    next_frontmatter &&
    makeSlug([next_frontmatter.timestamp, next_frontmatter.title], "news");

  return (
    <Layout customMenu={[["Tutte le news", "/news"]]}>
      <Section
        containerProps={{
          padding: 8,
        }}
      >
        <Heading mb={8}>{md.frontmatter.title}</Heading>

        {/* MARKDOWN CONTENT */}
        <MarkdownContainer
          dangerouslySetInnerHTML={{
            __html: md.html,
          }}
        />

        {/* NEWS NAVIGATION */}
        <Flex
          direction={["column", "row"]}
          justify={["center", "space-between"]}
          align={"center"}
        >
          {prevPost && (
            <Link
              as={GatsbyLink}
              to={prevPost}
              display={"block"}
              textAlign={"center"}
              border="2px"
              borderColor="accent.03"
              color="accent.03"
              lineHeight={"26px"}
              h={"auto"}
              w={260}
              py={3} // => 'n' * 4 px
              px={4} // => 'n' * 4 px
              mb={[4, 0]}
              textTransform={"uppercase"}
              _hover={{
                textDecoration: "none",
                bg: "hover.02",
              }}
            >
              <Flex justify={"space-evenly"}>
                <ArrowLeft width={"26px"} height={"26px"} />
                Post precedente
              </Flex>
            </Link>
          )}

          {nextPost && (
            <Link
              as={GatsbyLink}
              to={nextPost}
              display={"block"}
              textAlign={"center"}
              border="2px"
              borderColor="accent.03"
              color="accent.03"
              lineHeight={"26px"}
              h={"auto"}
              w={260}
              py={3} // => 'n' * 4 px
              px={4} // => 'n' * 4 px
              mb={[4, 0]}
              textTransform={"uppercase"}
              _hover={{
                textDecoration: "none",
                bg: "hover.02",
              }}
            >
              <Flex justify={"space-evenly"}>
                Post successivo
                <ArrowRight width={"26px"} height={"26px"} />
              </Flex>
            </Link>
          )}
        </Flex>
      </Section>
    </Layout>
  );
};

export default NewsDetail;
